import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { MarketoIntegration } from "models";
import { IntegrationData, MarketoData, updateIntegration } from "api";
import { marketoSchema } from "../marketo-table.constants";
import { initialValues } from "./update-marketo.constants";

import styles from "../../integrations-list.module.scss";

interface UpdateMarketoProps {
  isOpen: boolean;
  item: MarketoIntegration;
  handleClose: () => void;
  onSuccess: () => void;
}

export const UpdateMarketo: React.FC<UpdateMarketoProps> = ({
  isOpen,
  item,
  handleClose,
  onSuccess,
}) => {
  const { submit, onCallSuccess, onCallError } = useCall(updateIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Marketo config updated.",
      "You can now use this updated config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with updating the Marketo integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<MarketoData>) => {
    await submit({ data: values, params: { id: item.id }, queryParams: "?apiType=marketo" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.formHeader}>
        <h1>Update Marketo config</h1>
        <p>Update Marketo config. This can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues(item)}
          onSubmit={handleSubmit}
          validationSchema={marketoSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                type="text"
                name="name"
                label="Config name"
                placeholder="Provide a config name"
              />
              <FormField
                type="text"
                name="properties.endpoint"
                label="Endpoint"
                placeholder="Endpoint"
              />
              <FormField
                type="text"
                name="properties.clientSecret"
                label="Client secret"
                placeholder="Client secret"
              />
              <FormField
                type="text"
                name="properties.clientId"
                label="Client ID"
                placeholder="Client ID"
              />
              <div className={styles.buttons}>
                <Button appearance="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
