import React, { useState } from "react";
import {
  Button,
  Search,
  Table,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { getInitialStorageFilters } from "utils";
import { AweberIntegration } from "models";
import { usePayload, useTablePagination } from "hooks";
import { getIntegrations } from "api";
import { IntegrationsQuery } from "../integrations-list.types";
import { AweberItem } from "./item/aweber-item";
import { CreateAweber } from "./create/create-aweber";
import { initialFilters, columns } from "./aweber-table.constants";

import styles from "../integrations-list.module.scss";

export const AweberTable: React.FC = () => {
  const { queryString, searchValue, setSearch } = useFilters<IntegrationsQuery>(
    getInitialStorageFilters<IntegrationsQuery>("aweber-integrations", initialFilters),
  );

  const [createOpen, setCreateOpen] = useState<boolean>(false);

  const aweberResponse = useFetch(getIntegrations.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, error, refresh } = usePayload(aweberResponse);

  const pagination = usePagination({ listPayload: payload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: "aweber-integrations",
  });

  const onCreateSuccess = () => {
    setCreateOpen(false);
    refresh();
  };

  return (
    <div>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />

        <Button onClick={() => setCreateOpen(true)}>Create AWeber integration</Button>
      </div>

      <Table
        columns={columns}
        list={payload?.data || []}
        loading={loading}
        row={(item) => <AweberItem item={item as AweberIntegration} refresh={refresh} />}
        error={error?.message}
        resetColumnsOnMount={false}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
      />

      <CreateAweber
        isOpen={createOpen}
        handleClose={() => setCreateOpen(false)}
        onSuccess={onCreateSuccess}
        // onSuccess={refresh}
      />
    </div>
  );
};
