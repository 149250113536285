import React from "react";
import { Modal } from "@epcnetwork/core-ui-kit";

import { Success } from "./success/success";
// import { SetCallbackUrl } from "./set-callback-url/set-callback-url";
import { Initialize } from "./initialize/initialize";
import { Credentials } from "./credentials/credentials";
import { CreateAweberContextProvider } from "./context/create-aweber.context";
import { AuthCode } from "./auth-code/auth-code";
// import { Authenticating } from "./authenticating/authenticating";

import styles from "./create-aweber.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const CreateAweber: React.FC<Props> = ({ isOpen, handleClose, onSuccess }) => {
  // todo: socket listener here

  return (
    <CreateAweberContextProvider>
      {({ step }) => (
        <Modal isOpen={isOpen} setClose={() => null} contentClassName={styles.modal}>
          {step === "initialize" && <Initialize handleClose={handleClose} />}
          {step === "credentials" && <Credentials />}
          {step === "auth-code" && <AuthCode />}
          {step === "success" && <Success onSuccess={onSuccess} />}
          {/*{step === "set-callback-url" && <SetCallbackUrl />}*/}
          {/*{step === "authenticating" && <Authenticating />}*/}
        </Modal>
      )}
    </CreateAweberContextProvider>
  );
};
