import React, { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Select,
  isEmpty,
  Label,
  Tooltip,
  Button,
  useCall,
  notification,
} from "@epcnetwork/core-ui-kit";

import { AweberIntegration, AweberListField, SelectOption } from "models";
import { getAweberListFields, getAweberLists } from "api";
import { useConfigs } from "../hooks/use-configs/use-configs.hook";
import { InitialValues } from "../../jobs-form.types";
import { AweberType } from "./aweber.types";
import { AweberTags } from "./aweber-tags/aweber-tags";
import { AweberName } from "./aweber-name/aweber-name";
import { AweberColumns } from "./aweber-columns/aweber-columns";

import { Download } from "assets/images";
import styles from "../../job-form.module.css";

type AweberProps = {
  endpointOrdinal: number;
};

export const Aweber: FC<AweberProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldTouched, setFieldValue, submitCount } =
    useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig } = useConfigs<AweberIntegration>("aweber");

  const aweberValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | AweberType
    | undefined;

  const { integrationId, listId } = aweberValues || {};

  const [lists, setLists] = useState<SelectOption<number>[]>([]);
  const [listFields, setListFields] = useState<AweberListField[]>([]);

  const getListsCall = useCall(getAweberLists);
  getListsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check Aweber integration");

    const optionsToSave: SelectOption<number>[] = payload.map(({ listId, name }) => ({
      value: listId,
      label: name,
    }));

    notification.success("Lists fetched!", "Select your list.");
    setLists(optionsToSave);
  });
  getListsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const getAweberFieldsCall = useCall(getAweberListFields);
  getAweberFieldsCall.onCallSuccess((payload) => {
    setListFields(payload);
  });
  getAweberFieldsCall.onCallError(() => {
    notification.error("Fetching error", "Could not fetch data extension fields, please try again");
  });

  const handleGetListsClick = () => async (selectedId?: number) => {
    setLists([]);

    const id = selectedId || integrationId;

    if (id) {
      await getListsCall.submit({
        data: {
          integrationId: id,
        },
      });
    }
  };

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.integrationId`, config.id);
      await handleGetListsClick()(config.id);
    }
  };

  const handleListSelect = async (option: SelectOption<number>) => {
    await setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.listId`,
      option?.value || 0,
    );
    // sync validation
    setTimeout(() =>
      setFieldTouched(`endpoints[${endpointOrdinal}].connector.properties.listId`, true),
    );

    if (option && integrationId) {
      await getAweberFieldsCall.submit({
        data: {
          integrationId,
          listId: option.value,
        },
      });
    }
  };

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <div>
        <Label isInputLabel text="Select AWeber integration" />
        <Select
          options={options}
          onChange={handleConfigChange}
          isSearchable
          searchPlaceholder="Search config"
          placeholder="Select integration"
          disableError
          disableClearing
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <AweberTags endpointOrdinal={endpointOrdinal} />
      <AweberName endpointOrdinal={endpointOrdinal} />

      <div className={styles.separator} />

      <div className={styles.listWrapper}>
        <Select
          label="List"
          placeholder="Select list"
          name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
          searchPlaceholder="Search lists"
          selectedOptionsKeys={listId}
          options={lists}
          onChange={handleListSelect}
          disabled={!integrationId}
          isSearchable
          error={errorText}
          asyncOptions={{
            loading: getListsCall.submitting,
          }}
        />
        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={!integrationId}
              onClick={handleGetListsClick}
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          Get lists
        </Tooltip>
      </div>

      {!!listId && listFields.length > 0 && (
        <AweberColumns
          endpointOrdinal={endpointOrdinal}
          fields={listFields}
          fieldsLoading={getAweberFieldsCall.submitting}
        />
      )}
    </>
  );
};
