import { array, number, object, SchemaOf, string } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { AweberColumn, AweberColumnAttribute, AweberName, AweberType } from "./aweber.types";

const marketoColumns: SchemaOf<AweberColumn> = object({
  field: string().required("Field is required"),
  column: string(),
});

const aweberColumnsSchema: SchemaOf<AweberColumnAttribute> = object({
  fileId: string().required("File ID is required"),
  columns: array().of(marketoColumns),
});

const aweberNameSchema: SchemaOf<AweberName> = object({
  fileId: string().required("File ID is required"),
  column: string(),
});

export const aweberSchema: SchemaOf<AweberType> = object({
  integrationId: number().required(requiredFieldText).min(1, requiredFieldText),
  listId: number().required(requiredFieldText).min(1, requiredFieldText),
  tags: array().of(string().required(requiredFieldText)).required(requiredFieldText),
  files: array().of(aweberColumnsSchema),
  names: array().of(aweberNameSchema),
});
