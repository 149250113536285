import { string, SchemaOf, object } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { AuthCodeData } from "./auth-code.types";

export const initialValues: AuthCodeData = {
  authorizationCode: "",
};

export const authCodeSchema: SchemaOf<AuthCodeData> = object({
  authorizationCode: string().required(requiredFieldText),
});
