import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { createIntegration, IntegrationData, SalesforceData } from "api";
import { salesforceSchema } from "../salesforce-table.constants";
import { initialValues } from "./create-salesforce.constants";

import styles from "../../integrations-list.module.scss";

interface CreateSalesforceProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const CreateSalesforce: React.FC<CreateSalesforceProps> = ({
  isOpen,
  handleClose,
  onSuccess,
}) => {
  const { submit, onCallSuccess, onCallError } = useCall(createIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Salesforce config created.",
      "You can now use this config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with creating Salesforce integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<SalesforceData>) => {
    await submit({ data: values, queryParams: "?apiType=salesforce" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.formHeader}>
        <h2>Create Salesforce config</h2>
        <p>Create config for Salesforce. This config can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={salesforceSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <FormField
                  type="text"
                  name="name"
                  label="Config name"
                  placeholder="Provide a config name"
                />
                <FormField
                  type="text"
                  name="properties.clientId"
                  label="Client ID"
                  placeholder="Client ID"
                />
                <FormField
                  type="text"
                  name="properties.clientSecret"
                  label="Client Secret"
                  placeholder="Client Secret"
                />
                <FormField
                  type="text"
                  name="properties.origin"
                  label="Origin"
                  placeholder="Origin"
                />
                <FormField
                  type="text"
                  name="properties.authOrigin"
                  label="Auth Origin"
                  placeholder="Auth Origin"
                />
                <FormField
                  type="text"
                  name="properties.soapOrigin"
                  label="Soap Origin"
                  placeholder="Soap Origin"
                />

                <div className={styles.buttons}>
                  <Button appearance="secondary" type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" loading={isSubmitting}>
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
