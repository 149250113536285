import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Button, FormField, notification, useCall } from "@epcnetwork/core-ui-kit";

import { AweberData, createIntegration, IntegrationData } from "api";
import { CreateAweberContext } from "../context/create-aweber.context";
import { AuthCodeData } from "./auth-code.types";
import { authCodeSchema, initialValues } from "./auth-code.constants";

import styles from "../create-aweber.module.scss";

export const AuthCode: React.FC = () => {
  const { setStep, credentials } = useContext(CreateAweberContext);

  const { submit, submitting, onCallSuccess, onCallError } = useCall(createIntegration);
  onCallSuccess(() => {
    setStep("success");
    notification.success("Success", "Aweber integration has been created!");
  });
  onCallError((error) => {
    notification.error("Error", error?.message);
  });

  const handleSubmit = async (values: AuthCodeData) => {
    if (credentials) {
      const { name, ...properties } = credentials;

      const data: IntegrationData<AweberData> = {
        name,
        apiType: "aweber",
        properties: {
          ...properties,
          authorizationCode: values.authorizationCode,
        },
      };

      await submit({ data });
    }
  };

  const goBack = () => {
    setStep("credentials");
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Provide AWeber authentication code</h2>
        <p>Enter authentication code to create an integration</p>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={authCodeSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <p className={styles.description} style={{ marginBottom: "24px" }}>
                Provide the authentication code. You can find it after completing the authentication
                flow in your browser's tab.
              </p>
            </div>
            <FormField
              type="text"
              name="authorizationCode"
              label="Authentication code"
              placeholder="Provide an authentication code"
            />

            <div className={styles.buttons}>
              <Button
                type="button"
                onClick={goBack}
                appearance="secondary"
                disabled={isSubmitting || submitting}
              >
                Back
              </Button>
              <Button type="submit" loading={isSubmitting || submitting}>
                Create integration
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
