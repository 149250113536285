import { useState, useRef, FC } from "react";
import {
  TableRow,
  TableCell,
  useCall,
  notification,
  MenuButton,
  Menu,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { AweberIntegration } from "models";
import { useAuthorization } from "hooks";
import { deleteIntegration } from "api";

import styles from "./aweber-item.module.scss";

type AweberItemProps = {
  item: AweberIntegration;
  refresh: VoidFunction;
};

export const AweberItem: FC<AweberItemProps> = ({ item, refresh }) => {
  const ability = useAuthorization();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isChanged, setIsChanged] = useState(false);

  const {
    submit: deleteSubmit,
    onCallError: onDeleteCallError,
    onCallSuccess: onDeleteCallSuccess,
  } = useCall(deleteIntegration);
  onDeleteCallSuccess(() => {
    notification.success("Deleted successfully", "Aweber integrations has been deleted");
    refresh();
  });
  onDeleteCallError((error) => {
    setIsChanged(false);
    notification.error("Could not delete this integration.", error.message);
  });

  const handleDelete = () => {
    notification.confirm(
      "Do you want to delete this integration?",
      "You will not have this integration as a predefined options while creating a new job.",
      {
        onOk: () => {
          setIsChanged(true);
          deleteSubmit({ params: { id: item.id } });
        },
        icon: "delete",
      },
    );
  };

  const canCreateIntegration = ability.can("create", "Integrations");
  const canDeleteIntegration = ability.can("delete", "Integrations");

  if (isChanged) {
    return null;
  }

  return (
    <TableRow id={item.id}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {item.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
          {item.properties.clientId}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
          {item.properties.clientSecret}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
          {item.properties.proxy}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div ref={menuRef}>
          {canCreateIntegration || canDeleteIntegration ? (
            <Menu>
              {canDeleteIntegration && (
                <MenuButton appearance="delete" onClick={handleDelete}>
                  Delete
                </MenuButton>
              )}
            </Menu>
          ) : (
            <div />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
