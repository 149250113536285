import React, { useState } from "react";

import { EndpointAPITypesKeys } from "models";
import { Container, TabOption, UnderlinedTabs } from "components";
import { UseInsiderTable } from "./use-insider/use-insider-table";
import { SalesforceTable } from "./salesforce/salesforce-table";
import { RemarketyTable } from "./remarkety/remarkety-table";
import { OmnisendTable } from "./omnisend/omnisend-table";
import { MarketoTable } from "./marketo/marketo-table";
import { IterableTable } from "./iterable/iterable-table";
import { GreenArrowTable } from "./green-arrow/green-arrow-table";
import { GetResponseTable } from "./get-response/get-response-table";
import { BlueshiftTable } from "./blueshift/blueshift-table";
import { AweberTable } from "./aweber/aweber-table";

import styles from "./integrations-list.module.scss";

export const IntegrationsListPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<EndpointAPITypesKeys>("iterable");

  const tabsOptions: (TabOption<EndpointAPITypesKeys> & { component: React.ReactNode })[] = [
    { label: "Iterable", value: "iterable", component: <IterableTable /> },
    { label: "Salesforce", value: "salesforce", component: <SalesforceTable /> },
    { label: "GreenArrow", value: "greenarrow", component: <GreenArrowTable /> },
    { label: "UseInsider", value: "useInsider", component: <UseInsiderTable /> },
    { label: "GetResponse", value: "getResponse", component: <GetResponseTable /> },
    { label: "Blueshift", value: "blueshift", component: <BlueshiftTable /> },
    { label: "Omnisend", value: "omnisend", component: <OmnisendTable /> },
    { label: "Remarkety", value: "remarkety", component: <RemarketyTable /> },
    { label: "Marketo", value: "marketo", component: <MarketoTable /> },
    { label: "AWeber", value: "aweber", component: <AweberTable /> },
  ];

  const onTabChange = (tab: TabOption<EndpointAPITypesKeys>) => {
    setCurrentTab(tab.value);
  };

  const currentTabComponent = tabsOptions.find((tab) => tab.value === currentTab)?.component;

  return (
    <Container>
      <UnderlinedTabs
        selected={currentTab}
        onChange={onTabChange}
        options={tabsOptions}
        className={styles.tabs}
      />

      <div className={styles.content}>{currentTabComponent}</div>
    </Container>
  );
};
