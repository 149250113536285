import moment from "moment-timezone";
import {
  addDays,
  addHours,
  arrayOf,
  convertToTimezone as convert,
  DateArgument,
  formatDate as format,
  getDiffInDays,
  getJSDate,
  getStartOfDay,
  isSameDay,
} from "@epcnetwork/core-ui-kit";

import store from "store";
import { userTimezone } from "constants/user.constants";

type DatesHoursArr = {
  date: Date;
  hours: [string, Date][];
};

export const getDatesHoursArr = (localFrom: Date, localTo: Date): DatesHoursArr[] => {
  const startHour = localFrom.getHours();
  const endHour = localTo.getHours();

  const singleDayHoursAmount = isSameDay(localFrom, localTo) ? endHour - startHour : 24 - startHour;
  const firstDayHoursAmount = 24 - startHour;
  const lastDayHoursAmount = getJSDate(addHours(-1, localTo)).getHours() + 1;
  const anyOtherDayHoursAmount = 24;

  const daysAmount = getDiffInDays(localFrom, +localTo - 1);

  return arrayOf(daysAmount, (dayIndex) => {
    const localDate = addDays(dayIndex, localFrom);
    const date = getJSDate(localDate);
    const isSingle = daysAmount === 1;
    const isFirst = dayIndex === 0;
    const isLast = dayIndex === daysAmount - 1;

    const hoursToRender = isSingle
      ? singleDayHoursAmount
      : isFirst
        ? firstDayHoursAmount
        : isLast
          ? lastDayHoursAmount
          : anyOtherDayHoursAmount;

    const indexOffset = isFirst ? startHour : 0;

    const hours: [string, Date][] = arrayOf(hoursToRender, (hourIndex) => {
      const index = hourIndex + indexOffset;
      const dateToAdd = isFirst ? date : getJSDate(getStartOfDay(localDate));
      return [`${index < 10 ? "0" : ""}${index}:00`, getJSDate(addHours(hourIndex, dateToAdd))];
    });

    return {
      date: getJSDate(localDate),
      hours,
    };
  });
};

export const convertToTimezone = (date: DateArgument): Date => {
  const state = store.getState();
  const timezone = state.ui.appTimezone;

  return convert(date, timezone, userTimezone);
};

// example "2024-03-10T19:00:00-04:00" is 19:00
export const getHourName = (hour: string): string => {
  return hour.split("T")[1].slice(0, 5);
};

// example "2024-03-10T19:00:00-04:00" is 04
export const getTimezoneInformation = (hour: string): number => {
  return +hour.slice(hour.length - 5, hour.length - 3);
};

// example "2024-03-10T19:00:00-04:00" is -
export const getTimezoneSign = (hour: string): string => {
  return hour.slice(hour.length - 6, hour.length - 5);
};

export const formatDate = (...args: Parameters<typeof format>): string => {
  if (!args[0]) return "";

  const date = convertToTimezone(args[0]);
  return format(date, args[1]);
};

// should be used for post requests on backend when we need to change GMT zone for the date
// user works with local - backend with DEFAULT_TIME_ZONE
export const getISODateWithAmericaTimezone = (date: Date): string => {
  const state = store.getState();
  const timezone = state.ui.appTimezone;

  return moment(date).tz(timezone, true).format();
};

export const getISOHourWithAmericaDaylightTimezone = (hour: Date, lastHour: Date): string => {
  const state = store.getState();
  const timezone = state.ui.appTimezone;
  const hourDate = moment(hour).tz(timezone, true).format().split("");
  const hourLastDate = moment(lastHour).tz(timezone, true).format();
  // override hour for day saving time
  // example "2024-03-10T19:00:00-04:00" is "2024-03-10T19:00:00-05:00"
  hourDate[hourDate.length - 4] = hourLastDate[hourLastDate.length - 4];

  return hourDate.join("");
};

export const getDateStringWithoutGTM = (day: string): string => {
  const gtmInfoLength = 6;
  return day.slice(0, -gtmInfoLength);
};

export const getDateWithoutGTM = (day: string): Date => {
  return new Date(getDateStringWithoutGTM(day));
};

export const getAllocationStartDate = (): Date => {
  const d = new Date();

  const newDate = getJSDate(addHours(1, d));
  const minutes = newDate.getMinutes();

  if (minutes <= 10) {
    const updatedDate = newDate;
    updatedDate.setMinutes(0);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return getJSDate(updatedDate);
  }

  const updatedDate = newDate;
  updatedDate.setMinutes(0);
  updatedDate.setSeconds(0);
  updatedDate.setMilliseconds(0);

  return getJSDate(addHours(1, updatedDate));
};
