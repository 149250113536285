import { FormikErrors } from "formik";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { AvailableFileModel, AweberListField } from "models";
import { AweberType, AweberColumn, AweberColumnAttribute } from "../aweber.types";

export const getInitialAweberColumns = (
  files: AvailableFileModel[],
  fields: AweberListField[],
): AweberColumnAttribute[] => {
  return files.map((file) => {
    const columns: AweberColumn[] = fields.map((field) => ({
      field: field.name,
      column: "",
    }));

    return { fileId: file.fileId, columns: columns };
  });
};

export const haveColumnsError = (
  errors: FormikErrors<InitialValues>,
  endpointOrdinal: number,
  fileIndex: number,
) => {
  const endpointObject = errors?.endpoints?.[endpointOrdinal];

  if (typeof endpointObject === "string") return false;

  const properties = endpointObject?.connector?.properties as unknown as AweberType;

  return typeof properties?.files?.[fileIndex] !== "undefined";
};
