import React from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { Check } from "assets/images";

import styles from "../create-aweber.module.scss";

type Props = {
  onSuccess: () => void;
};

export const Success: React.FC<Props> = ({ onSuccess }) => {
  const handleButtonClick = () => {
    onSuccess();
  };

  return (
    <div>
      <div className={styles.successWrapper}>
        <div className={styles.row}>
          <Check className={styles.successIcon} />
          <p className={styles.successText}>Completed!</p>
        </div>

        <div style={{ textAlign: "center" }}>
          <p className={styles.description} style={{ marginBottom: 0 }}>
            The configuration process is completed!
          </p>
          <p className={styles.description} style={{ marginTop: 0 }}>
            You can now close this tab and use your integration.
          </p>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button type="submit" onClick={handleButtonClick}>
          Close
        </Button>
      </div>
    </div>
  );
};
