import React, { useState } from "react";

import { CredentialsData } from "../credentials/credentials.types";
import { CreateAweberStep } from "../create-aweber.types";

type CreateAweberContextType = {
  step: CreateAweberStep;
  setStep: (step: CreateAweberStep) => void;

  credentials: CredentialsData | null;
  setCredentials: (data: CredentialsData | null) => void;

  resetState: () => void;
};

const initialValue: CreateAweberContextType = {
  step: "initialize",
  setStep: (step: CreateAweberStep) => null,

  credentials: null,
  setCredentials: (url: CredentialsData | null) => null,

  resetState: () => null,
};

export const CreateAweberContext = React.createContext<CreateAweberContextType>(initialValue);

interface Props {
  children: (context: CreateAweberContextType) => React.ReactNode;
}

export const CreateAweberContextProvider: React.FC<Props> = ({ children }) => {
  const [step, setStep] = useState<CreateAweberStep>("initialize");
  const [credentials, setCredentials] = useState<CredentialsData | null>(null);

  const resetState = () => {
    setStep("initialize");
    setCredentials(null);
    setCredentials(null);
  };

  const state: CreateAweberContextType = {
    step,
    setStep,
    credentials,
    setCredentials,
    resetState,
  };

  return (
    <CreateAweberContext.Provider value={state}>{children(state)}</CreateAweberContext.Provider>
  );
};
