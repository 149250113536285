import React from "react";
import { useFormikContext } from "formik";
import { Button, FormField, Label } from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { AweberType } from "pages/jobs/form/connectors/aweber/aweber.types";

import { CrossRed } from "assets/images";
import styles from "../../../job-form.module.css";

import mainStyles from "./aweber-tags.module.scss";

interface Props {
  endpointOrdinal: number;
}

export const AweberTags: React.FC<Props> = ({ endpointOrdinal }) => {
  const { values, setFieldValue } = useFormikContext<InitialValues>();

  const aweberValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | AweberType
    | undefined;

  const tagsField = `endpoints[${endpointOrdinal}].connector.properties.tags`;

  const addTag = async () => {
    const tags = aweberValues?.tags || [];

    await setFieldValue(tagsField, [...tags, ""]);
  };

  const handleTagRemove = async (tagIndex: number) => {
    const tags = aweberValues?.tags || [];
    const newTags = tags.filter((_, index) => index !== tagIndex);

    await setFieldValue(tagsField, newTags);
  };

  const tagText = aweberValues?.tags.length === 0 ? "Add first tag" : "Add another tag";

  return (
    <div style={{ padding: "12px 0" }}>
      <div className={styles.row}>
        <div>
          <p className={mainStyles.mainLabel}>Tags</p>
          <p className={mainStyles.description}>Add AWeber tags</p>
        </div>
        <Button type="button" btnSize="small" onClick={addTag} style={{ fontSize: "12px" }}>
          {tagText}
        </Button>
      </div>

      <div className={styles.column}>
        {aweberValues?.tags.map((tag, index) => {
          return (
            <div key={index} className={styles.aweberListWrapper}>
              <FormField
                type="text"
                name={`${tagsField}[${index}]`}
                inputSize="small"
                placeholder="Enter tag"
                disableError
              />
              <button
                type="button"
                onClick={() => handleTagRemove(index)}
                className={styles.deleteButton}
              >
                <CrossRed />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
