import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Button, FormField } from "@epcnetwork/core-ui-kit";

import { CreateAweberContext } from "../context/create-aweber.context";
import { CredentialsData } from "./credentials.types";
import { credentialsSchema, generateUrl, initialValues } from "./credentials.constants";

import styles from "../create-aweber.module.scss";

export const Credentials: React.FC = () => {
  const { setStep, credentials, setCredentials } = useContext(CreateAweberContext);

  const handleSubmit = (data: CredentialsData) => {
    setCredentials(data);

    const url = generateUrl(data.clientId);
    window.open(url, "_blank");

    setStep("auth-code");
  };

  const handleBack = () => {
    setStep("initialize");
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Provide AWeber developer account credentials</h2>
        <p>Enter all necessary credentials</p>
      </div>
      <Formik
        initialValues={initialValues(credentials)}
        onSubmit={handleSubmit}
        validationSchema={credentialsSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormField
              type="text"
              name="name"
              label="Integration name"
              placeholder="Provide an integration name"
              required
            />
            <FormField
              type="text"
              name="clientId"
              label="Client ID"
              placeholder="Provide a developer client ID"
              required
            />
            <FormField
              type="text"
              name="clientSecret"
              label="Client secret"
              placeholder="Provide a developer client secret"
              required
            />
            <FormField
              type="text"
              name="proxy"
              label="Proxy"
              placeholder="Provide a proxy"
              required
            />

            <div>
              <p className={styles.description} style={{ marginBottom: 0 }}>
                When you click "next" button, you will be redirected to the AWeber OAuth 2.0 flow.
              </p>
              <p className={styles.description} style={{ marginTop: 0 }}>
                Sign in to your account and copy the "Authorization code" after the authorization
                process is completed.
              </p>
            </div>

            <div className={styles.buttons}>
              <Button type="button" onClick={handleBack} appearance="secondary">
                Back
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
