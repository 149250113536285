import { string, SchemaOf, object } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { CredentialsData } from "./credentials.types";

export const initialValues = (credentials: CredentialsData | null): CredentialsData => ({
  name: credentials?.name || "",
  clientId: credentials?.clientId || "",
  clientSecret: credentials?.clientSecret || "",
  proxy: credentials?.proxy || "",
});

export const credentialsSchema: SchemaOf<CredentialsData> = object({
  name: string().required(requiredFieldText),
  clientId: string().required(requiredFieldText),
  clientSecret: string().required(requiredFieldText),
  proxy: string()
    .matches(/(http|https):\/\/(\S+):(\S+)@(\S+):(\d+)/gm, "Proxy should match the proper format")
    .min(3, "Proxy is too short")
    .required(requiredFieldText),
});

export const generateUrl = (clientId: string) => {
  const redirectUri = "urn:ietf:wg:oauth:2.0:oob";
  const scope = "list.read+account.read+subscriber.write";

  return `https://auth.aweber.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  // return `https://auth.aweber.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=STATE_TOKEN`;
};
