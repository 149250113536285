import React, { useContext } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { CreateAweberContext } from "../context/create-aweber.context";

import styles from "../create-aweber.module.scss";

type Props = {
  handleClose: () => void;
};

export const Initialize: React.FC<Props> = ({ handleClose }) => {
  const { setStep, resetState } = useContext(CreateAweberContext);

  const handleButtonClick = () => {
    setStep("credentials");
  };

  const close = () => {
    handleClose();
    resetState();
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Create AWeber integration</h2>
        <p>It takes a few steps to complete the entire configuration process.</p>
      </div>

      <section className={styles.stepsWrapper}>
        <div className={styles.steps}>
          <span className={styles.stepNumber}>1</span>
          <div>
            <h4>Provide a required credentials</h4>
            <p>
              Enter client ID and client secret of AWeber developer account. It is required to
              proceed with the authorization flow.
            </p>
          </div>
        </div>

        <div className={styles.steps}>
          <span className={styles.stepNumber}>2</span>
          <div>
            <h4>Sign in and enter the authorization code</h4>
            <p>
              On this step you will be redirected to AWeber authorization page. Sign in to your
              account and <strong>copy the authorization code.</strong>
            </p>
          </div>
        </div>

        <div className={styles.steps}>
          <span className={styles.stepNumber}>3</span>
          <div>
            <h4>Provide authorization code</h4>
            <p>Provide the code code that was copied from Aweber authorization page.</p>
          </div>
        </div>
      </section>

      <div className={styles.buttons}>
        <Button appearance="secondary" onClick={close}>
          Close
        </Button>
        <Button type="button" onClick={handleButtonClick}>
          Create AWeber configuration
        </Button>
      </div>
    </div>
  );
};
