import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { searchMinLength } from "constants/form.constants";
import { IntegrationsQuery } from "../integrations-list.types";

export const initialFilters: UseFiltersProps<IntegrationsQuery> = {
  searchOptions: { searchKey: "search", searchMinLength },
  initialState: { search: "", limit: defaultLimit, offset: defaultOffset, apiType: "aweber" },
};

export const columns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Client ID" },
  { label: "Client secret" },
  { label: "Proxy" },
  { label: "" },
];
