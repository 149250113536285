import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { createIntegration, IntegrationData, RemarketyData } from "api";
import { remarketySchema } from "../remarkety-table.constants";
import { initialValues } from "./create-remarkety.constants";

import styles from "../../integrations-list.module.scss";

interface CreateRemarketyProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const CreateRemarkety: React.FC<CreateRemarketyProps> = ({
  isOpen,
  handleClose,
  onSuccess,
}) => {
  const { submit, onCallSuccess, onCallError } = useCall(createIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Remarkety config created.",
      "You can now use this config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with creating Remarkety integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<RemarketyData>) => {
    await submit({ data: values, queryParams: "?apiType=remarkety" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.formHeader}>
        <h2>Create Remarkety config</h2>
        <p>Create config for Remarkety. This config can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={remarketySchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                type="text"
                name="name"
                label="Config name"
                placeholder="Provide a config name"
              />
              <FormField
                type="text"
                name="properties.apiKey"
                label="API Key"
                placeholder="API Key"
              />
              <FormField
                type="text"
                name="properties.storeId"
                label="Store ID"
                placeholder="Store ID"
              />

              <div className={styles.buttons}>
                <Button appearance="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
