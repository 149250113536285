import { Nullable } from "@epcnetwork/core-ui-kit";

import { JobProgressStatuses } from "types";
import { EndpointAPIContentType } from "pages/jobs/form/endpoint/endpoint-api-dispatcher";
import { AllocationStatusModel, TagShortModel } from "models";

export type JobModel = {
  id: number;
  name: string;
  webhookId: number;
  webhook: {
    name: string;
    ip: string;
  };
  apiType: EndpointAPITypesKeys;
  fileIds: string[];
  timezone: string;
  startDate: string;
  stats: JobStats;
  endDate: string;
  paused: boolean;
  status: JobProgressStatuses;
  ownerId: number;
  filename: string;
  createdAt: string;
  hidden: boolean;
  proxyId?: Nullable<string>;
  proxyValue?: Nullable<string>;
  tag?: TagShortModel;
} & EndpointAPIContentType;

export type JobStats = {
  total: number;
  totalSent: number;
  totalFailed: number;
};

export type JobModelConfiguration = JobModel & {
  batchId: number;
  calculationInProgress: boolean;
  connector: ConnectorFileType;
  connectorId: number;
  allowAutofreeze: boolean;
  emailCount: number;
  attachedFiles: AvailableFileModel[];
  files: AvailableFileModel[];
};

export type ProxyModel = {
  id: string;
  createdAt: string;
  user: string;
  password: string;
  protocol: string;
  address: string;
  port: string;
  ownerId: number;
};

export type JobsBatchModel = {
  batchId: number;
  createdAt: string;
  tag?: TagShortModel;
  totalToAllocate: number;
  fileIds: string[];
  files: AvailableFileModel[];
  status: AllocationStatusModel;
  endpoints: ReusableFileModel[];
  attachedFiles: AvailableFileModel[];
};

export type JobCountModel = {
  afterEmailCalculation: string | number;
  deleted: string | number;
  failed: string | number;
  finished: string | number;
  hidden: string | number;
  inProgress: string | number;
  frozen: string | number;
  paused: string | number;
  pending: string | number;
  preconfigured: string | number;
  preparation: string | number;
  all: string | number;
  total: string | number;
  jobPreconfigured: string | number;
  offset: number;
  limit: number;
  reconfigurationNeeded: number;
  autofrozen: string;
};

export enum EndpointAPITypes {
  restApi = "Rest API",
  blueshift = "Blueshift",
  salesforce = "Exact Target (Salesforce)",
  iterable = "Iterable",
  omnisend = "Omnisend",
  remarkety = "Remarkety",
  greenarrow = "GreenArrow",
  useInsider = "UseInsider",
  getResponse = "GetResponse",
  marketo = "Marketo",
  aweber = "AWeber",
}

export type EndpointAPITypesKeys = keyof typeof EndpointAPITypes;

export enum HttpMethodTypes {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export type HttpMethodTypesKeys = keyof typeof HttpMethodTypes;

export enum BodyTypes {
  "application/json" = "json",
  "application/x-www-form-urlencoded" = "urlencoded",
}

export type BodyTypesKeys = keyof typeof BodyTypes;

export type WebhookModel = {
  address: string;
  id: number;
  ip: string;
  name: string;
  externalIp: Nullable<string>;
};

export type AvailableFileModel = {
  date: string;
  emailCount: number;
  columns: string[];
  fileId: string;
  id: number;
  fileName: string;
  is_used: boolean;
};

export type ConnectorFileType = {
  id?: string;
  apiType: EndpointAPITypesKeys;
  properties: EndpointAPIContentType | null;
};

export type ReusableFileModel = {
  name: string;
  startDate: string;
  endDate: string;
  id: number;
  connector: ConnectorFileType;
  proxyId?: Nullable<string>;
  proxyValue?: Nullable<string>;
  webhookId: number;
  jobId?: string;
  tag?: string;
};

export type SoapDataModel = {
  listId?: string;
  id?: string;
  name: string;
};

export type GetResponseCampaign = {
  campaignId: string;
  name: string;
};

export type DataExtensionField = {
  PartnerKey: string;
  ObjectID: string;
  Name: string; // -> emailProperty
  FieldType: string;
  IsRequired: "true" | "false";
};

export type MarketoForm = {
  formId: number;
  name: string;
};

export type AweberList = {
  listId: number;
  name: string;
};

export type AweberListsData = {
  integrationId: number;
};

export type MarketoFormField = {
  formFieldId: string;
  label: string;
  isRequired: boolean;
};

export type AweberListField = {
  fieldId: number;
  name: string;
};

export enum JobTrafficTypes {
  succeededTraffic = "succeededTraffic",
  failedTraffic = "failedTraffic",
  suppressedButSentTraffic = "suppressedButSentTraffic",
  targetTraffic = "targetTraffic",
}

export type JobTrafficTypesKeys = keyof typeof JobTrafficTypes;

export enum JobTrafficRescheduleReasons {
  frozenTrafficCompensation = "frozen-traffic-compensation",
}

export type JobTrafficModel = {
  date: string;
  failed: number;
  successful: number;
  suppressed: number;
  suppressedButSent: number;
  target: number;
  rescheduledFrom: string | null;
  rescheduleReason: JobTrafficRescheduleReasons | null;
  frozenTrafficRescheduled: boolean;
};

export type JobTrafficStatsModel = {
  traffic: JobTrafficModel[];
};

export type JobMetaDomainModel = {
  domain: string;
  totalEmailCount: string;
  sentEmailCount: string;
  remainingEmailCount: string;
  failedEmailCount: string;
  successEmailCount: string;
  totalSuppressed: string;
};

export type JobMetaStatsModel = {
  meta: {
    aggregatedByDomain: JobMetaDomainModel[];
    lastHourFailed: string;
    lastHourSent: string;
    lastHourLeft: string;
    lastHourSuccessful: string;
    lastHourSuppressed: string;
    lastHourToBeSent: number;
    suppressedButSent: string;
    totalFailed: string;
    totalLeft: string;
    totalSent: string;
    totalSuccessful: string;
    totalSuppressed: string;
    totalToBeSent: number;
  };
};

export type JobIPSModel = {
  name: string;
};

export type EmailStatuses = "success" | "failed";

export type JobLogModel = {
  email: string;
  id: number;
  responseId: number;
  status: EmailStatuses;
  sentAt: string;
  newlyAdded?: boolean;
};

export type JobLogResponseModel = {
  response: string;
};

// string could be "2023-08-11T06:55:00.202Z" format from BE side for reconfigureDate
export type JobActivityDataModel = { data: JobProgressStatuses | string };

export type JobLogActivityModel = {
  field: "status" | "reconfigureDate";
  newValue: JobActivityDataModel;
  oldValue: JobActivityDataModel;
  createdAt: string;
  id: number;
  jobId: 0;
};
