import {
  AvailableFileModel,
  AweberList,
  AweberListField,
  AweberListsData,
  DataExtensionField,
  GetResponseCampaign,
  JobCountModel,
  JobIPSModel,
  JobLogActivityModel,
  JobLogModel,
  JobLogResponseModel,
  JobMetaStatsModel,
  JobModel,
  JobModelConfiguration,
  JobsBatchModel,
  JobTrafficStatsModel,
  List,
  MarketoForm,
  MarketoFormField,
  ProxyModel,
  ReusableFileModel,
  SoapDataModel,
  WebhookModel,
} from "models";
import { apiMiddleware, MarketoData } from "api";
import {
  CreateJobBatchData,
  DeleteJobsBatchData,
  EmailsSuppression,
  EndpointBatchData,
  HideJobsBatchData,
  JobPauseStatusData,
  SoapData,
} from "./jobs.interface";

export const getJobs = apiMiddleware<List<JobModel> & JobCountModel>()({
  method: "get",
  endpoint: "/job/list",
});

export const getJobsRefresh = apiMiddleware<List<JobModel> & JobCountModel>()({
  method: "get",
  endpoint: "/job/list-refresh",
});

export const getJob = apiMiddleware<JobModelConfiguration>()({
  method: "get",
  endpoint: "/job/:jobId",
});

export const getProxies = apiMiddleware<ProxyModel[]>()({
  method: "get",
  endpoint: "/proxies",
});

export const getJobsBatch = apiMiddleware<JobsBatchModel>()({
  method: "get",
  endpoint: "/job/batches/:batchId",
});

export const getJobMetaStats = apiMiddleware<JobMetaStatsModel>()({
  method: "get",
  endpoint: `/stats/stats-meta/:jobId`,
});

export const postJobPauseStatus = apiMiddleware<null, JobPauseStatusData>()({
  method: "post",
  endpoint: "/job/pause",
});

export const postJobFreezeStatus = apiMiddleware<JobModel, null>()({
  method: "post",
  endpoint: "/job/:jobId/freeze",
});

export const postJobUnfreezeStatus = apiMiddleware<JobModel, null>()({
  method: "post",
  endpoint: "/job/:jobId/unfreeze",
});

export const getJobChartStatsHourly = apiMiddleware<JobTrafficStatsModel | null>()({
  method: "get",
  endpoint: "/stats/:jobId/chart/hourly",
});

export const getJobChartStatsDaily = apiMiddleware<JobTrafficStatsModel | null>()({
  method: "get",
  endpoint: "/stats/:jobId/chart/daily",
});

export const getJobLogs = apiMiddleware<List<JobLogModel>>()({
  method: "get",
  endpoint: `/logs/:jobId`,
});

export const getJobActivityLogs = apiMiddleware<List<JobLogActivityModel>>()({
  method: "get",
  endpoint: `/job/:jobId/logs`,
});

export const getJobLogResponse = apiMiddleware<JobLogResponseModel>()({
  method: "get",
  endpoint: `/logs/:jobId/response/:responseId`,
});

export const getJobLogsDownload = apiMiddleware<string>()({
  method: "get",
  endpoint: `/logs/download`,
});

export const getJobIPS = apiMiddleware<JobIPSModel[]>()({
  method: "get",
  endpoint: "/stats/isps",
});

export const getOngoingJobs = apiMiddleware<JobModel[]>()({
  method: "get",
  endpoint: "/job/ongoing-jobs",
});

export const postJobsInABatch = apiMiddleware<null, CreateJobBatchData>()({
  method: "post",
  endpoint: "/job-configuration/preconfigure",
});

export const postHideJob = apiMiddleware<null, Pick<JobModel, "hidden">>()({
  method: "post",
  endpoint: `/job/toggle-hide/:jobId`,
});

export const postHideJobsBatch = apiMiddleware<null, HideJobsBatchData>()({
  method: "post",
  endpoint: `/job/batch-hide`,
});

export const postJobSuppressionEmails = apiMiddleware<number, EmailsSuppression>()({
  method: "post",
  endpoint: `/admin/suppress-emails`,
  isFormData: true,
});

export const deleteJob = apiMiddleware()({
  method: "delete",
  endpoint: `/job/:jobId`,
});

export const deleteJobs = apiMiddleware<null, DeleteJobsBatchData>()({
  method: "delete",
  endpoint: `/job/batch-delete`,
});

export const getWebhooks = apiMiddleware<WebhookModel[]>()({
  method: "get",
  endpoint: "/webhooks",
});

export const getAvailableUsedFiles = apiMiddleware<List<AvailableFileModel>>()({
  method: "get",
  endpoint: "/files/available/used",
});

export const getAvailableNewFiles = apiMiddleware<List<AvailableFileModel>>()({
  method: "get",
  endpoint: "/files/available/not-used",
});

export const getReusableFiles = apiMiddleware<List<ReusableFileModel>>()({
  method: "get",
  endpoint: "/job/reusable",
});

export const getDataExtensions = apiMiddleware<SoapDataModel[], SoapData>()({
  method: "post",
  endpoint: "/connectors/salesforce/get-data-extensions",
});

export const getDataExtensionFields = apiMiddleware<DataExtensionField[], SoapData>()({
  method: "post",
  endpoint: "/connectors/salesforce/get-data-extension-fields",
});

export const getSalesforceLists = apiMiddleware<SoapDataModel[], SoapData>()({
  method: "post",
  endpoint: "/connectors/salesforce/get-lists",
});

export const getMarketoForms = apiMiddleware<MarketoForm[], SoapData>()({
  method: "post",
  endpoint: "/connectors/marketo/get-forms",
});

export const getAweberLists = apiMiddleware<AweberList[], AweberListsData>()({
  method: "post",
  endpoint: "/connectors/aweber/get-lists",
});

export const getAweberListFields = apiMiddleware<
  AweberListField[],
  { listId: number; integrationId: number }
>()({
  method: "post",
  endpoint: "/connectors/aweber/get-custom-fields",
});

export const getMarketoFields = apiMiddleware<
  MarketoFormField[],
  MarketoData & { formId: number }
>()({
  method: "post",
  endpoint: "/connectors/marketo/get-form-fields",
});

export const getGreenArrowLists = apiMiddleware<SoapDataModel[], SoapData>()({
  method: "post",
  endpoint: "/connectors/greenarrow/get-lists",
});

export const getGetResponseCampaigns = apiMiddleware<GetResponseCampaign[], SoapData>()({
  method: "post",
  endpoint: "/connectors/get-response/get-campaigns",
});

export const postEditBatch = apiMiddleware<EndpointBatchData>()({
  method: "post",
  endpoint: "/job/batches/:batchId",
});

export const postEditJob = apiMiddleware<null, CreateJobBatchData>()({
  method: "post",
  endpoint: "/job/:jobId",
});
